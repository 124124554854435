<script>
import RegionsForm from './RegionsForm'

export default {
  extends: RegionsForm,

  methods: {
    onSubmit() {
      this.createRegion(this.form)
        .then(() => {
          this.successSubmit({ message: 'Регион успешно создан', routeName: 'regions' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>